import Body from '../components/core/body';
import planning from "@/router/planning";
import events from "@/router/events";
import dashboard from "@/router/dashboard";
import bookings from "@/router/bookings";
import marketing from "@/router/marketing";
import playground from "@/router/playground";
import clients from "@/router/clients";
import auth from "@/router/auth";
import reports from "@/router/reports";
import settings from "@/router/settings";
import lessons from "@/router/lessons";
import users from "@/router/users";
import shop from "@/router/shop";
import subscriptions from "@/router/subscriptions";
import contributions from "@/router/contributions";
import credits from "@/router/credits";
import articles from "@/router/articles";
import creditsPacks from "@/router/credits-packs";
import accessControl from "@/router/access-control";
import webhooks from '@/router/webhooks';
import walletRefills from "@/router/wallet-refills";
import sales from "@/router/sales";
import liveAccesses from "@/router/live-accesses";
import tickets from "@/router/tickets";
import caisse from "@/router/caisse";
import quote from '@/router/quote';
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import store from "@plugins/store";

const permissionsQuery = localStorage.getItem('userPermissions');

const club = store.getters["auth/currentClub"];
const userPermissions = permissionsQuery !== 'undefined' && isNotUndefinedAndNotNull(permissionsQuery) ? JSON.parse(permissionsQuery) : [];
const homePage = userPermissions.find(el => el === 'CLUB_PLANNING_READ');
const customerPage = userPermissions.find(el => el === 'CLUB_CLIENT_READ');

const homeRoute = userPermissions.length === 0 ? {name: 'planning'} : isNotUndefinedAndNotNull(homePage) ? {name: 'planning'} : club.nextoreAccount ? {name: 'caisse_index'} : isNotUndefinedAndNotNull(customerPage) ? {name: 'users'} : {name: 'settings'};

const routes = [
  {
    path: '', redirect: homeRoute
  },
  {
    path: '/',
    component: Body,
    meta: {
      requiresAuth: true
    },
    children: [
      dashboard,
      planning,
      settings,
      ...users,
      ...lessons,
      ...shop,
      ...sales,
      ...liveAccesses,
      ...accessControl,
      ...webhooks,
      ...walletRefills,
      ...articles,
      ...events,
      ...marketing,
      ...subscriptions,
      ...contributions,
      ...playground,
      ...credits,
      ...creditsPacks,
      ...clients,
      ...bookings,
      ...reports
    ]
  },
  auth
];

export default routes;
