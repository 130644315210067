import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const USER_CLUB_URL = '/user-clubs';

export const getClubCoachs = (itemsPerPage, page, search ='', filters = '', exportCSV = false) =>
{
  const URL = exportCSV === false ? USER_CLUB_URL +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + '&search=' + search + filters :
    USER_CLUB_URL +
    '?club.id=' + clubId + filters + '&export=true'
  ;
  return   _get(URL)
}

export const postUserClub = (userClub) => _post(USER_CLUB_URL, userClub);

export const getUserAccesses = (userId) => _get(USER_CLUB_URL + '/' + userId + '/clubs/' + clubId + '/user-access-profile');

export const putUserClub = (userClub) => _put(USER_CLUB_URL + '/' + userClub.id, userClub);

export const putUserClubEnabled = (id, enabled) => _put(USER_CLUB_URL + '/' + id, { enabled });

export const deleteUserClub = (userClubId) => _delete(USER_CLUB_URL + '/' + userClubId);
