export default {
  removeTicket: (context) => {
    context.commit('clearSale');
  },
  removeProduct: (context, payload) => {
    context.commit('removeProduct', payload);
  },
  updateQuantity: (context, payload) => {
    context.commit('updateQuantity', payload);
  },
  updatePrice: (context, payload) => {
    context.commit('updatePrice', payload);
  },
  addPayment: (context, payload) => {
    context.commit('addPayment', payload);
  },
  addProduct: (context, payload) => {
    context.commit('addProduct', payload);
    context.commit('setTapeValue', 0);
  },
  setTapeValue: (context, payload) => {
    context.commit('setTapeValue', payload);
  },
  showModal: (context, payload) => {
    context.commit('setShowModal', payload);
  },
  clearSale: (context, payload) => {
    context.commit('clearSale', payload);
  },
  setRendu: (context, payload) => {
    context.commit('setRendu', payload);
  },
  setCustomer: (context, payload) => {
    context.commit('setCustomer', payload);
  },
  setBooking: (context, payload) => {
    context.commit('setBooking', payload);
  }
}
