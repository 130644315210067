export default [
  {
    path: 'news',
    name: 'news',
    component: () => import('@views/articles/Index'),
  },
  {
    path: 'articles/new',
    name: 'new_news',
    component: () => import('@views/articles/createOrUpdate/Index'),
  },
  {
    path: 'articles/update/:id',
    name: 'news_update',
    component: () => import('@views/articles/createOrUpdate/Index'),
  }
];
