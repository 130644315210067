export default {
  getPlaygrounds: (state) => {
    return state.playgrounds;
  },
  getLoader: (state) => {
    return state.loader;
  },
  getSelectedBlockPrices: (state) => {
    return state.selectedBlockPrices;
  },
  getLoadedBlockPricesSelectedByPlayground: (state) => {
    return state.loadedBlockPricesSelectedByPlayground;
  },
  getClubTimetables: (state) => {
    return state.clubTimetables;
  },
  getBlockPricesTotalItems: (state) => {
    return state.selectedBlockPrices.length;
  },
  getSelectedTimetables: (state) => {
    return state.selectedTimetables;
  },
  getTimetablesTotalItems: (state) => {
    return state.selectedTimetables.length;
  },
}
