const moment = require('moment');

export default {
  getBlockPrices: (state) => {
    return state.blockPrices;
  },
  getTickets: (state) => {
    return state.tickets;
  },
  getParticipantCategories: (state) => {
    return state.participantCategories;
  },
  getPlaygrounds: (state) => {
    return state.playgrounds;
  },
  getDisplaySessionsModal: (state) => {
    return state.displaySessionsModal;
  },
  getDisplayCashRegistriesModal: (state) => {
    return state.displayCashRegistriesModal;
  },
  getDisplayExpiredRegisterModal: (state) => {
    return state.displayExpiredRegisterModal;
  },
  isValidRegistry: (state) => {
    return (moment.utc().diff(moment(state.register.date_utc), 'hours') <= 24);
  },
  getSession: (state, getters, rootState, rootGetters) => {
    if (state.session) {
      return state.session.club === rootGetters['auth/currentClub']['@id'] ? state.session : {};
    }

    return {};
  },
  getSessions: (state) => {
    return state.sessions;
  },

  getDisplayNoRegisterModal: (state) => state.displayNoRegisterModal,

  getDisplayNoCashRegisterModal: (state) => state.displayNoCashRegisterModal,

  getRegister: (state, getters, rootState, rootGetters) => {
    if (state.register && state.session) {
      return state.register.user_id === state.session.id && rootGetters['auth/currentClub']['@id'] === state.register.club  ? state.register : {};
    }

    return {};
  },
  getActiveRegisters: (state) => {
    return state.activeRegisters;
  },
}
