const path = 'reports';

export default [
  {
    path: path + '/payment',
    name: 'payment',
    component: () => import('@views/reports/payment/Index'),
  },
  {
    path: path + '/subscriptions',
    name: 'subscription',
    component: () => import('@views/reports/subscriptions/Index'),
  },
  {
    path: path + '/fill',
    name: 'fill',
    component: () => import('@views/reports/fill/FillReport.vue'),
  },
  {
    path: path + '/credits',
    name: 'credits-report',
    component: () => import('@views/reports/credit/Index'),
  },
  {
    path: path + '/bookings',
    name: 'bookings-report',
    component: () => import('@views/reports/bookings/Index'),
  },
  {
    path: path + '/wallet',
    name: 'wallet-report',
    component: () => import('@views/reports/wallet/Index'),
  }
];
