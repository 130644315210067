export default [
  {
    path: "contributions",
    name: "contributions",
    component: () => import('@views/contributions/ContributionList.vue'),
  },
  {
    path: "contributions/new",
    name: "contribution_new",
    component: () => import("@views/contributions/ContributionInsert.vue"),
  },
  {
    path: "contributions/update/:id",
    name: "contribution_update",
    component: () => import("@views/contributions/ContributionUpdate.vue"),
  },
];
