import Vue from 'vue';
import App from '@/App.vue';

Vue.config.productionTip = false;
Vue.config.silent = true;

let appVue;

const loadAppVue = () => {
  let plugins;

  const promises = [
    import('@/plugins').then((obj) => {
      plugins = obj;
    }),
    import('@/filters'),
    import('@/directives'),
  ];

  Promise.allSettled(promises)
    .then(() => {
      appVue = new Vue({
        ...plugins.default,
        render: h => h(App)
      });
    })
    .finally( () => appVue.$mount('#app'))
    .catch((err) => {
      console.error('An error has been occurred when mounting App', err);
    })
  ;
};

loadAppVue();
