export default {
  sale: {
    products: [],
    payments: []
  },

  method: null,
  tapeValue: 0,
  rendu: 0,
  creationDate: null,
  customer: null,
  booking: null,
  showModal: false,
  tickets: [],
  nextoreProducts: [],
}
