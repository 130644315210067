export default {
  setCustomerStats: (state, payload) => {
    state.customerStats = payload;
  },

  setClubJauge: (state, payload) => {
    state.clubJauge = payload;
  },
  setLoadingJauge: (state, payload) => {
    state.isLoadingJauge = payload;
  },
};
