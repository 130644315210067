export default {
  getVariations: (state) => {
    return state.variations;
  },
  getIsLoadedVariations: (state) => {
    return state.isLoadedVariations;
  },
  getUpdatedStartAt: (state) => {
    return state.updatedStartAt;
  },
  getLastBlockPrice: (state) => {
    return state.lastBlockPrice;
  },
  getFirstLoad: (state) => {
    return state.firstLoad;
  },
  getLoadedParticipants: (state) => {
    return state.loadedParticipants;
  },
  getLoadedLessons: (state) => {
    return state.loadedLessons;
  },
  getSelectedTab: (state) => {
    return state.selectedTab;
  },
}
