<template>
  <section
    :data-type="dataType"
    class="login-wrap"
  >
    <div
      :data-type="dataType"
      class="login-left"
    >
      <img
        :data-type="dataType"
        :class="processing ? 'animate-flicker': ''"
        class="login-left-logo"
        src="@/assets/images/doinsport/icon-doinsport.svg"
      />
    </div>
    <transition
      name="fade"
    >
      <div
        v-if="!processing"
        class="login-right"
      >
        <router-view/>
      </div>
    </transition>
  </section>
</template>
<script>
export default {
  computed: {
    processing() {
      return this.$store.getters['auth/getConnecting'];
    },
    dataType() {
      return this.processing ? 'processing' : '';
    }
  }
}
</script>
<style lang="scss" src="@/assets/scss/auth/auth.scss" scoped/>
