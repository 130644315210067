import {clubId} from "@api/doinsport/services/httpService";
import axios from "axios";

const URL = 'actualities';

export const getDoinsportActualities= () => {
  return new Promise((resolve, reject) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if(!token) {
      return reject(null)
    }
    axios({
      url: process.env.VUE_APP_API_V4 + URL + `?club.id=${clubId}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err);
      })
  })
};
