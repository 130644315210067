export default {
  playgrounds: [],
  selectedBlockPrices: [],
  blockPricesTotalItems: null,
  selectedTimetables: [],
  clubTimetables: [],
  timetablesTotalItems: null,
  loadedBlockPricesSelectedByPlayground: false,
  loader: true,
}
